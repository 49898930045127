// eslint-disable-next-line no-undef
const photoStoreEndPoint =
  "https://storage.googleapis.com/ohana-portal.appspot.com/bin/public";

const logos = {
  MRR_Logo: `${photoStoreEndPoint}/logos/MRR_Logo.png`,
  MRR_Logo_TG: `${photoStoreEndPoint}/logos/MRR_Logo_TG.png`,
  MRR_Logo_XMAS: `${photoStoreEndPoint}/logos/MRR_Logo_XMAS.png`,
  MRR_Logo_White_Icon: `${photoStoreEndPoint}/logos/MRR_Logo_White_Icon`,
  MRR_Logo_White_Full: `${photoStoreEndPoint}/logos/MRR_Logo_White_Full`,
  redsky_logo: `${photoStoreEndPoint}/misc/redsky_logo.jpg`,
  redskysuntrip: `${photoStoreEndPoint}/misc/redskysuntrip.png`,
  badges: {
    inc_5000_Logo: `${photoStoreEndPoint}/badges/Inc-5000-Logo.png`,
    airbnb_superhost: `${photoStoreEndPoint}/misc/badges/airbnb-superhost.png`,
    bbb: `${photoStoreEndPoint}/misc/badges/bbb.png`,
    best_place_to_work: `${photoStoreEndPoint}/misc/badges/best-place-to-work.png`,
    hawaiis_50_fastest: `${photoStoreEndPoint}/misc/badges/hawaiis-50-fastest.png`,
    mcoc: `${photoStoreEndPoint}/misc/badges/mcoc.png`,
    vrma: `${photoStoreEndPoint}/misc/badges/vrma.png`,
  },
  //https://storage.googleapis.com/ohana-portal.appspot.com/bin/public/icons/play_icon_dark_blue.png
  play_button_icons: {
    MRR_Dark: `${photoStoreEndPoint}/icons/play_icon_dark_blue.png`,
    MRR_Light: `${photoStoreEndPoint}/icons/play_icon_light_blue.png`,
  },
};

const mrrEnv = (() => {
  let mrrEnvironment =
    process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-production"
      ? "production"
      : process.env.REACT_APP_MRR_ENVIRONMENT === "mrr-staging"
      ? "staging"
      : "development";
  return mrrEnvironment;
})();

const environment = {
  port: process.env.PORT!,
  env: process.env.NODE_ENV!,
  mrrEnv,
};

const salesforce_api = {
  rest: process.env.REACT_APP_SF_APEX_REST_API_ENDPOINT!,
  domain: process.env.REACT_APP_SF_DOMAIN!,
  api_host: process.env.REACT_APP_API_HOST!,
  client_id: process.env.REACT_APP_API_CLIENT_ID!,
  x_api_key: process.env.REACT_APP_X_API_KEY!,
};

const api_keys = {
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN!,
  synk_code: process.env.REACT_APP_SNYK_CODE!,
};

const firebase_api = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID!,
  appId: process.env.REACT_APP_FIREBASE_APPID!,
};

const firebase_notification_key =
  process.env.REACT_APP_FIREBASE_Web_Push_Certificate_Key!;

const jestUser = {
  email: process.env.REACT_APP_TEST_USER_EMAIL || "Not yet implemented in env!",
  password:
    process.env.REACT_APP_TEST_USER_PASSWORD || "Not yet implemented in env!",
};

export {
  environment,
  salesforce_api,
  api_keys,
  logos,
  firebase_api,
  firebase_notification_key,
  jestUser,
};
