import * as Sentry from "@sentry/react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import { CacheProvider } from "./cache-provider";
import AuthGuard from "./components/auth/auth-guard";
import RedirectWithToken from "./components/auth/redirect-with-token";
import SalesforceGuard from "./components/salesforce/salesforce-guard";
import { TaskDrawerProvider } from "./components/task-drawer";
import {
  PATH_TASK_ROOT_ALL_TASKS,
  PATH_TASK_ROOT_CLEANINGS,
  PATH_TASK_ROOT_DELIVERIES,
  PATH_TASK_ROOT_DROP_STRIPS,
  PATH_TASK_ROOT_ENGINEERING,
  PATH_TASK_ROOT_FINAL_INSPECTIONS,
  PATH_TASK_ROOT_GLASS_LANAI,
  PATH_TASK_ROOT_GUEST_EXPERIENCE,
  PATH_TASK_ROOT_HOUSEKEEPING,
  PATH_TASK_ROOT_MAINTENANCE_INSPECTIONS,
  PATH_TASK_ROOT_MY_TASKS,
  PATH_TASK_ROOT_OVERDUE,
  PATH_TASK_ROOT_OWNER_EXPERIENCE,
  PATH_TASK_ROOT_SHUTTLE_SERVICE,
  PATH_TASK_ROOT_SPECIAL_PROJECTS,
  PATH_TASK_ROOT_UNASSIGNED,
  TasksPageParams,
} from "./config/path-config"
import { api_keys, environment } from "./config";
import { AppLayout } from "./layout/app-layout";
import {
  TasksPage,
  HomePage,
  ListingPage,
  ListingsPage,
  TaskPage,
  UnknownRoutePage,
  UserPage,
  ReservationsPage,
} from './page';
import { clientVersion } from "./utils/version";
import './tailwind.css';
import ReservationModalProvider from "./components/reservation-modal";

export const internalVersion = 8; // bump for remote build tests

const sentryEnv = environment.mrrEnv ? environment.mrrEnv : 'production';

Sentry.init({
  environment: sentryEnv,
  dsn: api_keys.sentry_dsn,
  release: clientVersion,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  // debug: true,

  replaysOnErrorSampleRate: 1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: sentryEnv === 'development' ? 1.0 : 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    })
  ]

});

const AppRouter = () => {
  return useRoutes(AppRoutes);
};

export const AppRoutes: RouteObject[] = [
  {
    path: '',
    element:
      <AuthGuard>
        <SalesforceGuard>
          <CacheProvider>
            <TaskDrawerProvider>
              <ReservationModalProvider>
                <AppLayout />
              </ReservationModalProvider>,
            </TaskDrawerProvider>
          </CacheProvider>
        </SalesforceGuard>
      </AuthGuard>,
    children: [
      { index: true, path: '', element: <Navigate to="/home" /> },
      { index: true, path: 'home', element: <HomePage /> },
      { index: true, path: 'users/:userId', element: <UserPage /> },
      {
        path: 'reservations',
        children: [
          { index: true, path: '', element: <ReservationsPage title='Daily Timeline' /> },
          { index: true, path: 'arrivals', element: <ReservationsPage title='Arrivals' /> },
          { index: true, path: 'departures', element: <ReservationsPage title='Departures' /> },
          { index: true, path: 'owner_arrivals', element: <ReservationsPage title='Owner Arrivals' /> },
          { index: true, path: 'check_in_requests', element: <ReservationsPage title='Check In Requests' /> },
        ]
      },
      {
        path: 'listings',
        children: [
          { index: true, path: '', element: <ListingsPage /> },
          { path: ':listingId', element: <ListingPage /> },
        ]
      },
      {
        path: 'tasks',
        children: [
          { index: true, path: ':taskId', element: <TaskPage /> },
          { index: true, path: '', element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_MY_TASKS, element: <TasksPage taskParam={TasksPageParams.My} /> },
          { index: true, path: PATH_TASK_ROOT_ALL_TASKS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_UNASSIGNED, element: <TasksPage taskParam={TasksPageParams.Unassigned} /> },
          { index: true, path: PATH_TASK_ROOT_OVERDUE, element: <TasksPage taskParam={TasksPageParams.Overdue} /> },
          { index: true, path: PATH_TASK_ROOT_DROP_STRIPS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_GLASS_LANAI, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_MAINTENANCE_INSPECTIONS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_CLEANINGS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_FINAL_INSPECTIONS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_SPECIAL_PROJECTS, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_DELIVERIES, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_SHUTTLE_SERVICE, element: <TasksPage taskParam={TasksPageParams.All} /> },
          { index: true, path: PATH_TASK_ROOT_ENGINEERING, element: <TasksPage taskParam={TasksPageParams.Unassigned} /> },
          { index: true, path: PATH_TASK_ROOT_HOUSEKEEPING, element: <TasksPage taskParam={TasksPageParams.Unassigned} /> },
          { index: true, path: PATH_TASK_ROOT_GUEST_EXPERIENCE, element: <TasksPage taskParam={TasksPageParams.Unassigned} /> },
          { index: true, path: PATH_TASK_ROOT_OWNER_EXPERIENCE, element: <TasksPage taskParam={TasksPageParams.Unassigned} /> },
          // { index: true, path: 'details/:taskId', element: <AllTaskPage /> },
        ]
      },
    ]
  },
  {
    path: 'oauth2/signup',
    element: <RedirectWithToken />
  },
  { path: 'login', element: <Navigate to='/home' /> }, // This is here to support legacy shortcuts.
  { path: '404', element: <UnknownRoutePage /> },
  { path: '*', element: <Navigate to="/404" /> }
]

export default AppRouter;
