import { Link, useLocation } from "react-router-dom";
import { useCache } from "../cache-provider";
import { useAuth } from "./auth/firebase-context";
import { useEffect, useState } from "react";
import { getUserSettingsByUsername, postUserSettingsByUsername } from "../utils/user-settings";
import { navigation } from "../config/path-config";
import brand from "../config/brand";
import { Disclosure, DisclosureButton, DisclosurePanel, Field, Label, Menu, MenuButton, MenuItem, MenuItems, Switch } from "@headlessui/react";
import clsx from "clsx";

const queueBasedNames = ['Engineering', 'Housekeeping', 'Guest Experience', 'Owner Experience', 'Shuttle Drivers'];
export function NavBar() {
  const { user, logout } = useAuth();
  const { myQueues } = useCache();
  const { pathname } = useLocation();
  const [userSettings, setUserSettings] = useState({ dark_mode: false })
  useEffect(() => {
    if (!user) {
      // console.warn('no user for persisted objects');
      return;
    }

    const fetchSettings = async () => {
      const currentSettings = await getUserSettingsByUsername(user.sf_user_id);
      setUserSettings(currentSettings)
    }

    fetchSettings();
  }, [user])
  const [nav, setNav] = useState(structuredClone(navigation));
  const handleClick = () => {
    // tell each nav item whether it's the current page
    const navClone = structuredClone(nav)
    navClone.forEach((v) => {
      const rootOfHRef = v.href.split('?')[0];
      v.current = pathname === rootOfHRef;
      if (v.children) {
        v.children.forEach((c) => {
          const rootOfHRef = c.href.split('?')[0];
          c.current = pathname === rootOfHRef;
          if (pathname === rootOfHRef) {
            v.current = true;
          }
        })
      }
      // console.log('CURRENT?', v.current, location.pathname, '===', rootOfHRef, '|', v.href);
    });
    setNav(navClone);
  };
  const myQueueNames = myQueues.data.map((q: any) => q.name);
  useEffect(() => {
    handleClick()
  }, [pathname])
  return (
    <div className="flex grow h-full flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 dark:text-white px-6">
      <div className="flex h-16 shrink-0 items-center bg-secondary-main -mx-6 px-6">
        <Link to="/">
          <img
            className="h-8 w-auto"
            src={brand.logoUrl}
            alt="logo icon" />
        </Link>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {nav.map((item) => {
                if (queueBasedNames.includes(item.name)) {
                  // this menu item is queue-based, so we need to check if the user is in the queue
                  if (!myQueueNames || !myQueueNames.includes(item.name)) {
                    return null;
                  }
                  // this menu item is allowed for this user
                }
                return (
                  <li key={item.name}>
                    {!Array.isArray(item?.children) ? (
                      <Link
                        to={item.href}
                        onClick={handleClick}
                        className={clsx(
                          item.current ? 'bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-100',
                        )}
                      >
                        {item.icon && <span aria-hidden="true" className={clsx(item.icon, "h-6 w-6 shrink-0 text-gray-400 ")} />}
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure as="div">
                        <DisclosureButton
                          onClick={handleClick}
                          className={clsx(
                            item.current ? 'bg-gray-100 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700',
                            'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 dark:text-gray-100',
                          )}
                        >
                          {item.icon && <span aria-hidden="true" className={clsx(item.icon, "h-6 w-6 shrink-0 text-gray-400")} />}
                          {item.name}
                          <span className='icon-[ic--round-keyboard-arrow-right] w-4 h-4 group-data-[open]:rotate-90 group-data-[open]:text-gray-500' />
                        </DisclosureButton>
                        <DisclosurePanel as="ul" className="mt-1 px-2">
                          {Array.isArray(item?.children) && item.children.map((subItem) => (
                            <li key={subItem.name}>
                              {/* 44px */}
                              <Link
                                to={subItem.href}
                                onClick={handleClick}
                                className={clsx(
                                  subItem.current ? 'bg-gray-100 font-semibold dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700',
                                  'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 dark:text-gray-100',
                                )}
                              >
                                {subItem.name}
                              </Link>
                            </li>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}
                  </li>
                )
              })}
            </ul>
          </li>
          {user &&
            <li className="hidden lg:block -mx-6 mt-auto sticky bottom-0 bg-gray-50 dark:bg-gray-800">
              <Menu as="div" className="relative">
                <MenuItems
                  className="absolute right-0 bottom-full z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    {({ focus }) => (
                      <Link
                        to={"users/" + user.sf_user_id}
                      >
                        <span className={clsx(focus ? 'bg-gray-100 dark:bg-gray-700' : '',
                          'p-4 text-sm text-gray-700 dark:text-white w-full flex items-center justify-between')}>
                          Profile
                          {/* TBD: do we want to show this on focus only? */}
                          {/* {focus && <span className="icon-[ic--round-keyboard-arrow-right] h-5 w-5" />} */}
                          <span className="icon-[ic--round-keyboard-arrow-right] h-5 w-5" />
                        </span>
                      </Link>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }) => (
                      <Field as="div" className='flex items-center justify-between p-4'>
                        <Label as="span" className="mr-3 text-sm text-gray-700 dark:text-white">
                          <span className="text-inherit">Dark Mode</span>
                        </Label>
                        <Switch
                          checked={userSettings.dark_mode}
                          onChange={async (dark) => {
                            document.documentElement.classList.toggle("dark", dark);
                            document.documentElement.classList.toggle("tw-dark", dark);
                            setUserSettings((prev) => ({ ...prev, dark_mode: dark }))
                            await postUserSettingsByUsername(user.sf_user_id, { ...userSettings, dark_mode: dark })
                          }}
                          className={clsx(
                            userSettings.dark_mode ? 'bg-primary-main dark:bg-secondary-main' : 'bg-gray-200 dark:bg-gray-700',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-main focus:ring-offset-2',
                          )}
                        >
                          <span className="sr-only">User setting</span>
                          <span
                            className={clsx(
                              userSettings.dark_mode ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white dark:bg-gray-900 shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          >
                            <span
                              className={clsx(
                                userSettings.dark_mode ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              )}
                              aria-hidden="true"
                            >
                              <span className="icon-[line-md--sunny-filled-loop]" />
                            </span>
                            <span
                              className={clsx(
                                userSettings.dark_mode ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                              )}
                              aria-hidden="true"
                            >
                              <span className='icon-[line-md--sunny-outline-to-moon-loop-transition]' />
                            </span>
                          </span>
                        </Switch>
                      </Field>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }) => (
                      <div className='p-2'>
                        <button
                          type='button'
                          onClick={async () => {
                            await logout();
                          }}
                          className={clsx(focus ? 'bg-error-main' : '', 'bg-error-dark rounded block px-4 py-2 text-sm text-white w-full')}
                        >
                          Log Out
                        </button>
                      </div>
                    )}
                  </MenuItem>
                </MenuItems>
                <MenuButton className="relative w-full flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  {/* <img
                          className="h-8 w-8 rounded-full bg-gray-100"
                          src={user?.profile_thumbnail_url || user?.profile_picture_url || ''}
                          alt={user.name} /> */}
                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                    {user?.first_name && user?.last_name && <span className="font-medium leading-none text-white">{user.first_name.charAt(0)}{user.last_name.charAt(0)}</span>}
                    {!user?.first_name || !user?.last_name && <span className="font-medium leading-none text-white">ME</span>}
                  </span>
                  <span aria-hidden="true">{user.name}</span>
                </MenuButton>
              </Menu>
            </li>
          }
        </ul>
      </nav>
    </div>
  )
}
