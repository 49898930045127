import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import useMakeSFRequest from '../request/salesforce/make-sf-call';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useId, useMemo, useState } from "react";
import { useCache } from '../cache-provider';
import { ApiTask } from '../types/task';
import { userHasPermission } from '../utils/user-has-permission';
import { useAuth } from './auth/firebase-context';
import { TaskDetails } from './task-details';

const TaskDrawerContext = createContext<{
  openTaskDrawer: ApiTask | null;
  setOpenTaskDrawer(a: ApiTask | null): void;
  taskRefreshCounter: number; setTaskRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
  taskPermission?: {
    is_my_task: boolean;
    is_manageable_by_user: boolean;
    is_claimable_by_user: boolean;
  } | undefined;
}>({ openTaskDrawer: null, setOpenTaskDrawer: () => undefined, taskRefreshCounter: 0, setTaskRefreshCounter: () => 0 });
export const TaskDrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const id = useId()
  const { user } = useAuth();
  const { myQueues } = useCache();
  const [taskRefreshCounter, setTaskRefreshCounter] = useState<number>(0);
  const [permissionToPerformActions, setPermissionToPerformActions] = useState({
    is_my_task: false,
    is_manageable_by_user: false,
    is_claimable_by_user: false
  });
  const [openDrawer, setOpenDrawer] = useState<ApiTask | null>(null);
  const handleOpenDrawer = useCallback((task: ApiTask | null) => {
    setOpenDrawer(task);
  }, [myQueues.data, user]);

  useEffect(() => {
    if (openDrawer) {
      setPermissionToPerformActions(userHasPermission(openDrawer, myQueues.data, user));
    }
  }, [openDrawer])

  const values = useMemo(() => {
    return {
      openTaskDrawer: openDrawer,
      setOpenTaskDrawer: handleOpenDrawer,
      taskRefreshCounter: taskRefreshCounter,
      setTaskRefreshCounter: setTaskRefreshCounter,
      ...(openDrawer && { taskPermission: permissionToPerformActions })
    }
  }, [openDrawer, taskRefreshCounter, setTaskRefreshCounter, permissionToPerformActions, handleOpenDrawer])
  return (
    <TaskDrawerContext.Provider value={values}>
      <TaskDrawer id={`task-drawer-${id}`} />
      {children}
    </TaskDrawerContext.Provider>
  );
};
export const useTaskDrawer = () => {
  const context = useContext(TaskDrawerContext);
  if (!context) {
    throw new Error('Hook must be called within TaskDrawerProvider.');
  }
  return context;
};
const TaskDrawer: React.FC<{ id: string }> = ({ id }) => {
  const { openTaskDrawer: task, setOpenTaskDrawer: setTask, taskPermission, setTaskRefreshCounter, } = useTaskDrawer();
  const [error, setError] = useState<string | null>(null);
  const clearError = useCallback(() => { setError(null); }, []);
  const onClose = () => {
    setTask(null);
    clearError()
  };
  const request = useMakeSFRequest();
  return (
    <Transition as='div' id={`task-drawer-${id}`} show={task !== null}>
      <Dialog className="relative z-50" onClose={onClose}>
        <TransitionChild
          as='div'
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <TransitionChild
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full translate-x-0 sm:translate-x-full sm:translate-y-0"
                enterTo="sm:translate-x-0 translate-y-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="sm:translate-x-0 translate-y-0"
                leaveTo="sm:translate-x-full translate-y-full sm:translate-y-0"
              >
                <DialogPanel className="pointer-events-auto relative w-full sm:w-96">
                  <TransitionChild
                    enter="ease-in-out duration-500 delay-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-100 delay-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed right-2 top-2 sm:hidden">
                      <button
                        type="button"
                        className="relative rounded-md focus:outline-none focus:ring-0"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <span className='icon-[carbon--close-outline] w-8 h-8 text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-gray-100' />
                      </button>
                    </div>
                  </TransitionChild>
                  {error && <p className='text-white text-center px-4 text-lg bg-error-dark'>{error}</p>}
                  {task && taskPermission &&
                    <TaskDetails
                      task={task}
                      id={id}
                      taskPermission={taskPermission}
                      refreshTask={async (taskData: any) => {
                        clearError();
                        if (taskData) {
                          // we fetch a new copy, because the response from edit is not a complete model, so our changes can be missing (e.g. perm to enter)
                          const response = await request({ endpointPath: `/api/task/${task.id}`, method: 'GET' });
                          if (response.error) {
                            setError(response.error.message ?? 'Failed to get task.');
                            return
                          }
                          else {
                            setTask(Object.assign(task, (response as any).task));
                            setTaskRefreshCounter(prev => prev + 1)
                          }
                        }
                      }}
                      drawerProps={{ onClose }
                      } />
                  }
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div >
      </Dialog>
    </Transition >
  )
};

