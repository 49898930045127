import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { logos, salesforce_api } from "../../config";

type SalesforceAuthModalProps = {
  open: boolean;
  reconnect: boolean;
  loginHint: string;
};

export const SalesforceAuthModalTW: React.FC<SalesforceAuthModalProps> = ({ open, reconnect, loginHint }) => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [salesforceLoginHint, setSalesforceLoginHint] = useState(loginHint);
  const [continueClicked, setContinueClicked] = useState<boolean>(false);

  const launchSFWindow = () => {
    try {
      window.open(
        SalesforceWindowLauncher(
          false,
          salesforceLoginHint
        ), "_self");
    }
    catch (e) {
      // this is a virtually impossible case to reach, but if something goes wrong, reset the butotm
      console.warn('failed to launch Salesforce window', e);
      setContinueClicked(false);
    }
  };
  return (
    <Transition as='div' show={open}>
      <Dialog className="relative z-[100]" onClose={() => { }}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center px-6">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div className="flex flex-col items-center my-4">
                  <div className="flex-none bg-secondary-main rounded-full">
                    <img src={logos.MRR_Logo_White_Icon} className='w-16 h-16 sm:w-24 sm:h-24' alt="logo" />
                  </div>
                  <h6 className="font-bold text-2xl sm:text-3xl mt-2 text-center">Authorize Salesforce</h6>
                  <p className="font-semibold text-lg mt-2 text-center text-gray-500"> We need to connect your Salesforce account to continue in the Ohana Portal.</p>
                </div>
                {errorMessage && <div className="rounded-md bg-error-main p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <span className="icon-[material-symbols--info-outline] h-5 w-5 text-white" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1">
                      <p className="text-white">There was a problem connecting with salesforce.</p>
                      <p className="text-end grow text-white">Return to <Link to='/logout' className="hover:underline hover:text-gray-200">login</Link></p>
                    </div>
                  </div>
                </div>}
                <button
                  type="button"
                  disabled={continueClicked}
                  onClick={() => {
                    setContinueClicked(true);
                    launchSFWindow();
                  }}
                  className="disabled:bg-gray-500 mt-2 w-full h-9 rounded-md bg-primary-main hover:bg-secondary-main text-white font-semibold"
                >
                  {continueClicked ? <span className='icon-[uiw--loading] animate-spin' /> : <span>Continue With Salesforce</span>}
                </button>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

const SalesforceWindowLauncher = (
  reauthorize: boolean,
  salesforceUsername: string | null) => {

  // console.log('SalesforceWindowLauncher called with '
  //   + 'reauthorize ' + reauthorize
  //   + ', salesforceUsername ' + salesforceUsername);

  if (reauthorize && salesforceUsername === null) {
    throw new Error("No username found to reauthorize.")
  }

  const REDIRECT_URI = salesforce_api.domain.concat(
    "/services/oauth2/authorize"
  );
  const urlSearchParams = new URL(REDIRECT_URI);
  let { pathname, href, origin } = window.location;

  // strip any query-string text from the current URL
  const questionMarkIndex = href.indexOf('?');
  if (questionMarkIndex !== -1) {
    href = href.slice(0, questionMarkIndex);
  }

  urlSearchParams.searchParams.append("client_id", salesforce_api.client_id);
  urlSearchParams.searchParams.append("response_type", "code");
  urlSearchParams.searchParams.append(
    "redirect_uri",
    `${origin}/oauth2/${reauthorize ? "reauthorize" : "signup"}`
  );
  urlSearchParams.searchParams.append("issued_at", `${performance.now()}`);
  urlSearchParams.searchParams.append("display", "touch");
  if (salesforceUsername !== null) {
    //NOTE: A username is not necessarily included. Certain characters in a username cause the
    //      request to be blocked by Salesforce.

    if (salesforceUsername.indexOf("+") < 0) {
      // This needs to be done inorder to ignore usernames with "+", such as aliased emails. ( Invalid salesforce loginHint )
      urlSearchParams.searchParams.append("login_hint", salesforceUsername);
    }
  }
  urlSearchParams.searchParams.append("state", encodeURIComponent(JSON.stringify({ pathname, salesforceUsername })));
  // console.warn(urlSearchParams.href)
  const redirect_uri = decodeURIComponent(urlSearchParams.href);
  // console.warn(redirect_uri)
  return redirect_uri;
};