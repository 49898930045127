import {
  cleaningsTabFilterOptions,
  specialProjectsTabFilterOptions,
} from "../../classes/task/task-strings";

export type NavChild = {
  name: string;
  href: string;
  current: boolean;
};

export type NavParent = {
  name: string;
  href: string;
  current: boolean;
  icon?: string;
  children?: NavChild[];
};

export const cleansSubtypeOptions = cleaningsTabFilterOptions
  .map((subType) => subType.replace(" & ", " And "))
  .join(",");
export const specialSubtypeOptions = specialProjectsTabFilterOptions
  .map((subType) => subType.replace(" & ", " And "))
  .join(",");
const deliveryOptions = ["Drop", "Pickup"].join(",");
// categories
export const PATH_TASK_ROOT_MY_TASKS = "my-tasks";
export const PATH_TASK_ROOT_ALL_TASKS = "all";
export const PATH_TASK_ROOT_UNASSIGNED = "unassigned";
export const PATH_TASK_ROOT_OVERDUE = "overdue";

// filter presets
export const PATH_TASK_ROOT_DROP_STRIPS = "DropStrips";
export const PATH_TASK_ROOT_GLASS_LANAI = "GlassLanai";
export const PATH_TASK_ROOT_MAINTENANCE_INSPECTIONS = "MaintenanceInspections";
export const PATH_TASK_ROOT_CLEANINGS = "Cleanings";
export const PATH_TASK_ROOT_FINAL_INSPECTIONS = "FinalInspections";
export const PATH_TASK_ROOT_SPECIAL_PROJECTS = "SpecialProjects";
export const PATH_TASK_ROOT_DELIVERIES = "Deliveries";
export const PATH_TASK_ROOT_SHUTTLE_SERVICE = "ShuttleService";

// group membership driven
export const PATH_TASK_ROOT_ENGINEERING = "Engineering";
export const PATH_TASK_ROOT_HOUSEKEEPING = "Housekeeping";
export const PATH_TASK_ROOT_GUEST_EXPERIENCE = "GuestExperience";
export const PATH_TASK_ROOT_OWNER_EXPERIENCE = "OwnerExperience";

export enum TaskSearchKey {
  Assignee = "assignee",
  Listing = "listing",
  Sort = "sortBy",
  View = "filterBy",
  StartDate = "taskStartDate",
  EndDate = "taskEndDate",
  Status = "status",
  SubType = "subtype",
  Type = "types",
  Priority = "priority",
}
export const TasksPageParams = {
  All: "allTasks",
  My: "assignedTasks",
  Unassigned: "unassignedTasks",
  Overdue: "overdueTasks",
};
export const PATH_TASKS = {
  MY_TASKS: "/tasks/" + PATH_TASK_ROOT_MY_TASKS,
  ALL_TASKS: "/tasks/" + PATH_TASK_ROOT_ALL_TASKS,

  DROP_STRIPS:
    "/tasks/" +
    PATH_TASK_ROOT_DROP_STRIPS +
    `?${TaskSearchKey.Type}=Clean&subtype=Drop %26 Strip`, // This %26 is an ampersand, i.e "Drop & Strip".
  MAINTENANCE_INSPECTION:
    "/tasks/" +
    PATH_TASK_ROOT_MAINTENANCE_INSPECTIONS +
    `?${TaskSearchKey.Type}=Inspection&subtype=Maintenance`,
  GLASS_LANAI:
    "/tasks/" +
    PATH_TASK_ROOT_GLASS_LANAI +
    `?${TaskSearchKey.Type}=Clean&subtype=Glass %26 Lanai`, // This %26 is an ampersand, i.e "Glass & Lanai".
  FINAL_INSPECTION:
    "/tasks/" +
    PATH_TASK_ROOT_FINAL_INSPECTIONS +
    `?${TaskSearchKey.Type}=Inspection&subtype=Final`,
  CLEANINGS:
    "/tasks/" +
    PATH_TASK_ROOT_CLEANINGS +
    `?${TaskSearchKey.Type}=Clean&subtype=` +
    encodeURIComponent(cleansSubtypeOptions),
  SPECIAL_PROJECTS:
    "/tasks/" +
    PATH_TASK_ROOT_SPECIAL_PROJECTS +
    `?${TaskSearchKey.Type}=Clean&subtype=` +
    encodeURIComponent(specialSubtypeOptions),
  DELIVERIES:
    "/tasks/" +
    PATH_TASK_ROOT_DELIVERIES +
    `?${TaskSearchKey.Type}=` +
    encodeURIComponent(deliveryOptions),
  SHUTTLE_SERVICE:
    "/tasks/" +
    PATH_TASK_ROOT_SHUTTLE_SERVICE +
    `?${TaskSearchKey.Type}=Shuttle Service`,

  UNASSIGNED: "/tasks/" + PATH_TASK_ROOT_UNASSIGNED,
  OVERDUE: "/tasks/" + PATH_TASK_ROOT_OVERDUE,

  ENGINEERING: "/tasks/" + PATH_TASK_ROOT_ENGINEERING,
  HOUSEKEEPING: "/tasks/" + PATH_TASK_ROOT_HOUSEKEEPING,
  GUEST_EXPERIENCE: "/tasks/" + PATH_TASK_ROOT_GUEST_EXPERIENCE,
  OWNER_EXPERIENCE: "/tasks/" + PATH_TASK_ROOT_OWNER_EXPERIENCE,
};

export const PATH_RESERVATIONS = {
  ROOT: "/reservations",
  ARRIVALS_TODAY:
    "/reservations/arrivals?hide_departures=true&hide_in_house=true",
  DEPARTURES_TODAY:
    "/reservations/departures?hide_arrivals=true&hide_in_house=true",
  OWNER_ARRIVALS:
    "/reservations/owner_arrivals?hide_departures=true&hide_in_house=true&type=Guest+of+Owner%2COwner",
  CHECK_IN_REQUESTS: "/reservations/check_in_requests?check_in_requests=true",
};

//NOTE: Order is important here! This sets the menu order.
export const navigation: NavParent[] = [
  {
    name: "Dashboard",
    href: "/home",
    icon: "icon-[ion--home]",
    current: true,
  },
  {
    name: "Listings",
    icon: "icon-[icon-park-twotone--hotel]",
    href: "/listings",
    current: false,
  },
  {
    name: "Reservations",
    icon: "icon-[mdi--calendar]",
    href: PATH_RESERVATIONS.ROOT,
    current: false,
    children: [
      { name: "Daily Timeline", href: PATH_RESERVATIONS.ROOT, current: false },
      {
        name: "Arrivals",
        href: PATH_RESERVATIONS.ARRIVALS_TODAY,
        current: false,
      },
      {
        name: "Departures",
        href: PATH_RESERVATIONS.DEPARTURES_TODAY,
        current: false,
      },
      {
        name: "Owner Arrivals",
        href: PATH_RESERVATIONS.OWNER_ARRIVALS,
        current: false,
      },
      {
        name: "Check In Requests",
        href: PATH_RESERVATIONS.CHECK_IN_REQUESTS,
        current: false,
      },
    ],
  },
  {
    name: "Tasks",
    icon: "icon-[clarity--tasks-outline-badged]",
    href: PATH_TASKS.MY_TASKS,
    current: false,
    children: [
      // always at the top
      { name: "My Tasks", href: PATH_TASKS.MY_TASKS, current: false },
      { name: "All Tasks", href: PATH_TASKS.ALL_TASKS, current: false },

      { name: "Drop/Strips", href: PATH_TASKS.DROP_STRIPS, current: false },
      {
        name: "Maintenance Inspections",
        href: PATH_TASKS.MAINTENANCE_INSPECTION,
        current: false,
      },
      { name: "Cleanings", href: PATH_TASKS.CLEANINGS, current: false },
      { name: "Glass & Lanai", href: PATH_TASKS.GLASS_LANAI, current: false },
      {
        name: "Final Inspections",
        href: PATH_TASKS.FINAL_INSPECTION,
        current: false,
      },
      {
        name: "Special Projects",
        href: PATH_TASKS.SPECIAL_PROJECTS,
        current: false,
      },
      { name: "Deliveries", href: PATH_TASKS.DELIVERIES, current: false },
      {
        name: "Shuttle Service",
        href: PATH_TASKS.SHUTTLE_SERVICE,
        current: false,
      },

      // these are dynamically added based on session user group membership
      { name: "Engineering", href: PATH_TASKS.ENGINEERING, current: false },
      { name: "Housekeeping", href: PATH_TASKS.HOUSEKEEPING, current: false },
      {
        name: "Guest Experience",
        href: PATH_TASKS.GUEST_EXPERIENCE,
        current: false,
      },
      {
        name: "Owner Experience",
        href: PATH_TASKS.OWNER_EXPERIENCE,
        current: false,
      },

      // always at the bottom
      { name: "Unassigned", href: PATH_TASKS.UNASSIGNED, current: false },
      { name: "Overdue", href: PATH_TASKS.OVERDUE, current: false },
    ],
  },
];
